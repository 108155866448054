<template>
    <div class="container container--header">
        <ion-header class="main-header" mode="md">
            <ion-toolbar class="main-header__toolbar">
                <ion-buttons slot="start">
                    <ion-button @click="goBackCallback" v-if="goBack">
                        <ion-icon
                            :ios="chevronBackOutline"
                            :md="chevronBackOutline"
                        ></ion-icon>
                    </ion-button>
                    <button
                        v-else
                        class="bg-transparent px-0"
                        @click="doShowMenu"
                    >
                        <font-awesome-icon :icon="['fal', 'bars']" size="2x" />
                    </button>
                </ion-buttons>
                <ion-buttons v-if="goBack && hasExtraBackButton" slot="end">
                    <ion-button @click="goBackCallback" class="text-lowercase">
                        {{ $t("general.cancel") }}
                    </ion-button>
                </ion-buttons>
                <ion-title mode="md">
                    <div
                        class="
                            main-header__title-container
                            py-2
                            text-uppercase
                            fs-12
                        "
                    >
                        <img
                            src="~@/assets/images/logo-small.svg"
                            alt="FruttaMe Logo"
                            class="mr-2 mt-n2"
                            width="30"
                            height="30"
                        />
                        <span>{{ plantName }}</span>
                    </div>
                </ion-title>
            </ion-toolbar>
        </ion-header>
    </div>
</template>

<script lang="ts" src="./TheHeader.ts"></script>

<style lang="scss" src="./TheHeader.scss"></style>
