import { createRouter, createWebHistory } from '@ionic/vue-router';
import { PAGE_404, PAGE_LOGIN } from './pages';
import routes from './routes';
import store from '@/core/store/store';
import fruttaMeClient from '../api/fruttame-service';
import { LOGOUT, SET_PROFILE } from '../store/action-types';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        next({
            name: PAGE_404,
        });

        return;
    }

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !store.state.profile) {
        try {
            const profile = await fruttaMeClient.me();
            store.dispatch(SET_PROFILE, profile);
        } catch (error) {
            if (error.status === 401) {
                store.dispatch(LOGOUT);
            }
        }
    }

    const { isAuthenticated } = store.getters;

    if (requiresAuth && !isAuthenticated) {
        next({
            name: PAGE_LOGIN,
        });
    } else {
        next();
    }
});

export default router;
