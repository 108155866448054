<template>
    <IonApp>
        <router-view></router-view>
    </IonApp>
</template>

<script lang="ts" src="./App.ts"></script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
</style>
