const SET_APP_LOADING = 'SET_APP_LOADING';
const SET_APP_LOADING_FORCE_OFF = 'SET_APP_LOADING_FORCE_OFF';
const SET_NETWORK_STATE = 'SET_NETWORK_STATE';
const SET_TOKEN = 'SET_TOKEN';
const LOGOUT = 'LOGOUT';
const SET_PROFILE = 'SET_PROFILE';
const SET_AUTH_STATE = 'SET_AUTH_STATE';
const READ_TOKEN = 'READ_TOKEN';

const SET_PLANT_LIST = 'SET_PLANT_LIST';
const SET_CURRENT_PLANT = 'SET_CURRENT_PLANT';

export {
    SET_APP_LOADING,
    SET_APP_LOADING_FORCE_OFF,
    SET_NETWORK_STATE,
    SET_TOKEN,
    LOGOUT,
    SET_PROFILE,
    SET_AUTH_STATE,
    READ_TOKEN,
    //plant
    SET_PLANT_LIST,
    SET_CURRENT_PLANT,
};
