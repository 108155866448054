import { toastController } from '@ionic/vue';

export interface MessageServiceInterface {
    message(message: string): Promise<void>;
    error(message: string): Promise<void>;
}

const message = async (message: string) => {
    const toast = await toastController.create({
        message: message,
        duration: 3000,
        position: 'bottom',
    });
    return toast.present();
};

const error = async (message: string) => {
    const toast = await toastController.create({
        message: message,
        duration: 3000,
        position: 'bottom',
        color: 'danger',
    });
    return toast.present();
};

export default { message, error } as MessageServiceInterface;
