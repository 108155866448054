import { App } from 'vue';
import { createI18n } from 'vue-i18n';
import messages from './strings/index';

// call with I18n option
const i18n = createI18n({
    locale: 'it', // set locale
    fallbackLocale: 'it',
    messages, // set locale messages
});

export const configI18n = (app: App<Element>) => {
    app.component('i18n');
};

export default i18n;
