import { createApp } from 'vue';
import axios from 'axios';
import mitt from 'mitt';

import router from './core/router';
import i18n, { configI18n } from './core/translations/i18n';
import store from './core/store/store';

import configIonic from './core/config/config-ionic';
import configFA from './core/config/config-fa';

import { IonicVue } from '@ionic/vue';
import { READ_TOKEN } from './core/store/action-types';

import App from './App.vue';
import TheHeader from '@/views/components/TheHeader/TheHeader.vue';
import messageUtils from './core/utilities/message-utils';
// PWA service worker
import './registerServiceWorker.ts';

import Hotjar from 'vue-hotjar';

if (!/Mobi|Android/i.test(navigator.userAgent)) {
    location.replace(process.env.VUE_APP_BASE_API_URL);
}

const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;
const configUrl = `${appRootUrl}/config.json`;
const emitter = mitt();

axios.get(configUrl).then((r) => {
    const app = createApp(App).use(router).use(store).use(i18n).use(IonicVue);

    if (process.env.VUE_APP_DESKTOP_URL === 'https://www.fruttame.com')
        app.use(Hotjar, {
            id: '2544717',
            isProduction: true,
        });

    app.config.globalProperties.$config = r.data;
    app.config.globalProperties.$messageService = messageUtils;
    app.config.globalProperties.$emitter = emitter;

    axios.defaults.baseURL = r.data.API_ENDPOINT;

    store.dispatch(READ_TOKEN);

    configI18n(app);
    configIonic(app);
    configFA(app);

    app.component('TheHeader', TheHeader);

    router.isReady().then(() => {
        app.mount('#app');
    });
});
