const stringsIT = {
    'general.enter': 'Accedi',
    'general.exitApp': 'Esci dall’applicazione',
    'general.production': 'Produzione',
    'general.plant': 'Stabilimento',
    'general.transport': 'Trasporto',
    'general.quantity': 'Quantità',
    'general.price': 'Prezzo',
    'general.loadDate': 'Data di carico',
    'general.viewPrices': 'Vizualizza prezzi',
    'general.lookingFor': 'Stiamo cercando i seguenti prodotti:',
    'general.noOffers':
        'Nessuna Offerta disponibile <br/> <small>(Riceverai una notifica mail non appena ci saranno offerte disponibili)</small>',
    'general.apiError':
        "C'è stato un problema nell'applicazione, si prega di riprovare più tardi",
    'general.close': 'Chiudi',
    'general.title': 'App Fruttame',
    'general.error': 'Errore',
    'general.back': 'Indietro',
    'general.cancel': 'Annulla',
    'general.send': 'Invia',
    'general.messaggeSent': 'Messaggio inviato',
    'general.messaggeSentDescription': '',
    'general.reportAProblem': 'Segnala un problema',
    'general.continue': 'Prosegui',

    'calendar.selectLoadDate': 'Seleziona una data di carico',
    'calendar.pricesInEuro': '(prezzi indicati Euro/kg)',
    'calendar.mon': 'Lun',
    'calendar.tue': 'Mar',
    'calendar.wed': 'Mer',
    'calendar.thu': 'Gio',
    'calendar.fri': 'Ven',
    'calendar.sat': 'Sab',

    'login.username': 'Utente',
    'login.password': 'Password',
    'login.rememberMe': 'Ricordami',
    'login.forgottenPassword': 'Password dimenticata?',
    'login.usernameOrPasswordWrong': 'Nome utente o password errati.',
    'login.onlySupplierLoginMessage':
        "L' app FruttaMe è al momento disponibile solo per i produttori. Scrivi a info&commat;fruttame.com per ulteriori informazioni.",

    'menu.dashboard': 'Bacheca offerte',
    'menu.planning': 'Pianificazione',
    'menu.archive': 'Archivio',
    'menu.calendar': 'Calendario',

    'plantSelector.success':
        'Stabilimento {plantName} selezionato correttamente',
    'plantSelector.selectPlant': 'Seleziona stabilimento',
    'plantSelector.selectPlantLong':
        'Seleziona lo stabilimento su cui vuoi operare:',
    'plantSelector.btn.confirm': 'Conferma stabilimento',
    'salesArchive.pagetitle': 'Archivio vendite',
    'salesArchive.noElements': 'Non ci sono elementi in archivio',
    'salesPlanning.pagetitle': 'carichi pianificati',
    'salesPlanning.pagesubtitle': 'trasporti ancora da organizzare',
    'salesPlanning.noElements': 'Non ci sono elementi da pianificare',
    'salesPlanning.week': 'Settimana',
    'sideMenu.logoutLabel': "Esci dall'applicazione",

    'transportDetail.departure': 'Partenza',
    'transportDetail.carrier': 'Trasportatore',
    'transportDetail.plate': 'Targhe',
    'transportDetail.engine': 'Motrice:',
    'transportDetail.trailer': 'Rimorchio:',
    'transportDetail.notes': 'Note',
    'transportDetail.assignmentNotes': 'Note di assegnazione',
    'transportDetail.reportProblem': 'Segnala un problema',
    'transportDetail.organized': 'Organizzato',
    colture: {
        0: 'Convenzionale',
        1: 'Biologica',
        2: 'GLOBALGAP',
    },
    'reportAProblem.organizedBadgeText': 'Organizzato',
    'reportAProblem.issueSent': 'Problema segnalato',
    'reportAProblem.issueSentText':
        'ti contatteremo il prima possibile per risolverlo',
    'reportAProblem.title': 'Segnala un problema',
    'reportAProblem.description':
        'Se ritieni ci siano dei problemi con la consegna del tuo ordine segnalacelo qui.',
    'reportAProblem.textareaPlaceholder': 'Scrivi qui il tuo problema',
    'saleConfirmation.title': 'Dettaglio offerta',
    'saleConfirmation.saleConfirmBtn': 'Conferma vendita',
    'saleConfirmation.saleConfirmTitle': 'Vendita confermata!',
    'saleConfirmation.saleConfirmDesc':
        "La vendita è avvenuta con successo. Il viaggio è ora in ricerca di trasporto. Controlla la tua email per maggiori informazioni sull'ordine.",
    'saleConfirmation.trackOrderBtn': "Traccia l'ordine",
    'saleConfirmation.backToCalendar': 'Torna al calendario',
    'saleConfirmation.scheduledTransportsCount':
        'Altri {scheduledTransportsCount} carichi programmati per questo giorno',
    'saleConfirmation.note':
        'Il prezzo finale sarà calcolato con peso franco attivo (DDP)',
};

export default stringsIT;
