import { Vue, Options } from 'vue-class-component';
import { IonApp, IonRouterOutlet, loadingController } from '@ionic/vue';
import { mapState, mapGetters } from 'vuex';
import { PAGE_LOGIN } from './core/router/pages';

@Options({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
    },
    computed: {
        ...mapState(['isAuthenticated']),
        ...mapGetters(['isLoading']),
    },
    watch: {
        isLoading: function (isLoading) {
            let timeoutDuration = 0;

            if (this.isHandlingLoader === true) {
                timeoutDuration = 1000;
            }

            setTimeout(() => {
                this.toggleLoader(isLoading);
            }, timeoutDuration);
        },
        isAuthenticated(value, oldValue) {
            if (oldValue && !value) {
                this.executeLogout();
            }
        },
    },
})
export default class App extends Vue {
    isHandlingLoader = false;
    loader: HTMLIonLoadingElement | null = null;
    async created() {
        this.loader = await loadingController.create({});
        this.loader.hidden = true;
        await this.loader.present();
    }

    async toggleLoader(isVisible: boolean) {
        this.isHandlingLoader = true;
        if (!this.loader) return;
        if (isVisible) {
            this.loader.hidden = false;
        } else {
            this.loader.hidden = true;
        }

        this.isHandlingLoader = false;
    }

    executeLogout() {
        this.$router.replace({
            name: PAGE_LOGIN,
        });
    }
}
