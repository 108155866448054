import { alertController } from '@ionic/vue';
import {
    PlantReferenceDTO,
    SupplierDashboardTransportDTOColture,
    UsermeDTO,
} from '@/../client-generator/generated-clients/client';
import i18n from '../translations/i18n';

const DEFAULT_WEIGHT = 25000;

const showError = async (error: Error | string) => {
    const alert = await alertController.create({
        header: i18n.global.t('global.error'),
        message: error.toString(),
        buttons: [
            {
                text: i18n.global.t('global.close'),
                role: 'ok',
                cssClass: 'primary',
            },
        ],
    });
    alert.present();
};

const parseJWT = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const getColtureName = (colture: SupplierDashboardTransportDTOColture) => {
    return i18n.global.t(`colture.${colture}`);
};

const getFavoritePlant = (profile: UsermeDTO) => {
    if (window.localStorage.getItem(`fruttaMe_currentPlant`))
        return JSON.parse(
            window.localStorage.getItem(`fruttaMe_currentPlant`) + ''
        ) as PlantReferenceDTO;
    if (profile.plant) return profile.plant;
    if (profile.myPlants) return profile.myPlants[0];

    return null;
};

export {
    DEFAULT_WEIGHT,
    showError,
    parseJWT,
    getFavoritePlant,
    getColtureName,
};
