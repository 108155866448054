const PAGE_LOGIN = 'page-login';
const PAGE_DASHBOARD = 'page-dashboard';
const PAGE_PLANT_SELECTOR = 'page-plant-selector';
const PAGE_SALES_ARCHIVE = 'page-sales-archive';
const PAGE_SALES_PLANNING = 'page-sales-planning';
const PAGE_SALES_PLANNING_DETAIL = 'page-sales-planning-detail';
const PAGE_SALES_ARCHIVE_DETAIL = 'page-sales-archive-detail';
const PAGE_CALENDAR = 'page-calendar';
const PAGE_404 = 'page-404';

export {
    PAGE_LOGIN,
    PAGE_DASHBOARD,
    PAGE_404,
    PAGE_PLANT_SELECTOR,
    PAGE_SALES_ARCHIVE,
    PAGE_SALES_PLANNING,
    PAGE_SALES_PLANNING_DETAIL,
    PAGE_SALES_ARCHIVE_DETAIL,
    PAGE_CALENDAR,
};
