import { RouteRecordRaw } from 'vue-router';
import {
    PAGE_404,
    PAGE_DASHBOARD,
    PAGE_LOGIN,
    PAGE_PLANT_SELECTOR,
    PAGE_SALES_ARCHIVE,
    PAGE_SALES_PLANNING,
    PAGE_SALES_PLANNING_DETAIL,
    PAGE_SALES_ARCHIVE_DETAIL,
    PAGE_CALENDAR,
} from './pages';

const MainContainer = () =>
    import(
        /* webpackChunkName: "main-container" */ '@/views/MainContainer/MainContainer.vue'
    );
const Dashboard = () =>
    import(
        /* webpackChunkName: "page-dashboard" */ '@/views/pages/Dashboard/Dashboard.vue'
    );
const LoginPage = () =>
    import(
        /* webpackChunkName: "page-login" */ '@/views/pages/Login/Login.vue'
    );
const SalesArchivePage = () =>
    import(
        /* webpackChunkName: "page-sales-archive" */ '@/views/pages/SalesArchive/SalesArchive.vue'
    );
const SalesPlaninngPage = () =>
    import(
        /* webpackChunkName: "page-sales-planning" */ '@/views/pages/SalesPlanning/SalesPlanning.vue'
    );
const Page404 = () =>
    import(/* webpackChunkName: "page-404" */ '@/views/pages/404/404.vue');
const PlantSelectorPage = () =>
    import(
        /* webpackChunkName: "page-plant-selector" */ '@/views/pages/PlantSelector/PlantSelector.vue'
    );
const TransportDetailPage = () =>
    import(
        /* webpackChunkName: "page-transport-detail" */ '@/views/pages/TransportDetail/TransportDetail.vue'
    );
const CalendarPage = () =>
    import(
        /* webpackChunkName: "page-calendar" */ '@/views/pages/Calendar/Calendar.vue'
    );

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: MainContainer,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: PAGE_DASHBOARD,
                component: Dashboard,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/sales-archive',
                name: PAGE_SALES_ARCHIVE,
                component: SalesArchivePage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/plant-selector',
                name: PAGE_PLANT_SELECTOR,
                component: PlantSelectorPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/sales-planning',
                name: PAGE_SALES_PLANNING,
                component: SalesPlaninngPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/sales-planning/:transportId',
                name: PAGE_SALES_PLANNING_DETAIL,
                component: TransportDetailPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/sales-archive/:transportId',
                name: PAGE_SALES_ARCHIVE_DETAIL,
                component: TransportDetailPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/calendar',
                name: PAGE_CALENDAR,
                component: CalendarPage,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/login',
        name: PAGE_LOGIN,
        component: LoginPage,
    },
    {
        path: '/404',
        name: PAGE_404,
        component: Page404,
    },
];

export default routes;
