import axios from 'axios';
import store from './store/store';
import { LOGOUT } from './store/action-types';
import { parseJWT } from './utilities/utils';

axios.defaults.timeout = 30000;

axios.interceptors.request.use((config) => {
    if (store.state.token)
        config.headers.Authorization = `Bearer ${store.state.token}`;
    else config.headers.Authorization = '';
    return config;
});

axios.interceptors.response.use(
    (response) => {
        if (response.data.succeded !== undefined && !response.data.succeded)
            return Promise.reject(response);
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            let expiredToken = false;
            if (!store.state.token) {
                expiredToken = true;
            } else {
                const decodedToken = parseJWT(store.state.token);
                if (!decodedToken) expiredToken = true;
                else
                    expiredToken =
                        decodedToken.exp < new Date().getTime() / 1000;
            }
            if (expiredToken) {
                await store.dispatch(LOGOUT);
            }
        }
        return Promise.reject(error);
    }
);

export default axios;
