import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';
import {
    PlantReferenceDTO,
    UsermeDTO,
} from '@/../client-generator/generated-clients/client';
import { chevronBackOutline } from 'ionicons/icons';

@Options({
    name: 'TheHeader',
    computed: {
        ...mapState(['profile', 'currentPlant']),
    },
    props: {
        goBack: {
            type: Function,
        },
        hasExtraBackButton: {
            type: Boolean,
        },
    },
})
export default class TheHeader extends Vue {
    profile!: UsermeDTO;
    currentPlant!: PlantReferenceDTO | null;
    chevronBackOutline = chevronBackOutline;
    goBack?: Function = undefined;

    get plantName() {
        return this.currentPlant?.name;
    }

    goBackCallback() {
        if (this.goBack) this.goBack();
    }

    doShowMenu() {
        this.$emitter.emit('do-show-menu');
    }
}
