import {
    PlantReferenceDTO,
    UsermeDTO,
} from '@/../client-generator/generated-clients/client';
import { createStore, Store } from 'vuex';
import * as Action from './action-types';
import { getFavoritePlant } from '../utilities/utils';

export interface AppState {
    counterLoading: number;
    isOnline: boolean;
    token: string | null;
    profile: UsermeDTO | null;
    isAuthenticated: boolean;
    plantList: PlantReferenceDTO[];
    currentPlant: PlantReferenceDTO | null;
}

const store = createStore({
    state: {
        counterLoading: 0,
        isOnline: true,
        token: null,
        profile: null,
        isAuthenticated: false,
        plantList: [],
        currentPlant: null,
    },
    mutations: {
        [Action.SET_APP_LOADING](state: AppState, isLoading: boolean) {
            if (isLoading) {
                state.counterLoading = state.counterLoading + 1;
                return;
            }
            if (state.counterLoading > 0)
                state.counterLoading = state.counterLoading - 1;
            else state.counterLoading = 0;
        },
        [Action.SET_CURRENT_PLANT](
            state: AppState,
            selectedPlant: PlantReferenceDTO
        ) {
            state.currentPlant = selectedPlant;
            window.localStorage.setItem(
                `fruttaMe_currentPlant`,
                JSON.stringify(selectedPlant)
            );
        },
        [Action.SET_APP_LOADING_FORCE_OFF](state: AppState) {
            state.counterLoading = 0;
        },
        [Action.SET_PLANT_LIST](
            state: AppState,
            plantList: PlantReferenceDTO[] = []
        ) {
            state.plantList = plantList;
        },
        [Action.SET_TOKEN](state: AppState, tokenObj: any) {
            state.token = tokenObj ? tokenObj.token : null;
            if (tokenObj) {
                if (tokenObj.rememberMe)
                    window.localStorage.setItem(
                        `fruttaMe_token`,
                        tokenObj.token
                    );
                else
                    window.sessionStorage.setItem(
                        `fruttaMe_token`,
                        tokenObj.token
                    );
            } else {
                window.localStorage.removeItem(`fruttaMe_token`);
                window.sessionStorage.removeItem(`fruttaMe_token`);
            }
        },
        [Action.SET_PROFILE](state, profile) {
            state.profile = profile;
        },
        [Action.SET_AUTH_STATE](state, authState) {
            state.isAuthenticated = authState;
        },
    },
    actions: {
        [Action.SET_APP_LOADING]({ commit }, isLoading) {
            commit(Action.SET_APP_LOADING, isLoading);
        },

        [Action.SET_APP_LOADING_FORCE_OFF]({ commit }) {
            commit(Action.SET_APP_LOADING_FORCE_OFF);
        },

        [Action.SET_NETWORK_STATE]({ commit }, isOnline) {
            commit(Action.SET_NETWORK_STATE, isOnline);
        },

        [Action.SET_TOKEN]({ commit }, tokenObj) {
            commit(Action.SET_TOKEN, tokenObj);
        },

        [Action.SET_CURRENT_PLANT](
            { commit },
            selectedPlant: PlantReferenceDTO
        ) {
            commit(Action.SET_CURRENT_PLANT, selectedPlant);
        },

        [Action.READ_TOKEN]({ commit }) {
            const localStorageToken =
                window.localStorage.getItem(`fruttaMe_token`);
            if (localStorageToken) {
                commit(Action.SET_TOKEN, {
                    token: localStorageToken,
                    rememberMe: true,
                });
            } else {
                const sessionStorageToken =
                    window.sessionStorage.getItem(`fruttaMe_token`);
                if (sessionStorageToken)
                    commit(Action.SET_TOKEN, {
                        token: sessionStorageToken,
                        rememberMe: false,
                    });
            }
        },

        [Action.LOGOUT]({ dispatch, commit }) {
            dispatch(Action.SET_TOKEN, null);
            commit(Action.SET_AUTH_STATE, false);

            // clean localstorage
            window.localStorage.removeItem('fruttaMe_currentPlant');
        },

        [Action.SET_PROFILE]({ commit, dispatch }, profile: UsermeDTO) {
            commit(Action.SET_PROFILE, profile);
            commit(Action.SET_PLANT_LIST, profile.myPlants);
            dispatch(Action.SET_CURRENT_PLANT, getFavoritePlant(profile));
            commit(Action.SET_AUTH_STATE, true);
        },
    },
    getters: {
        isAuthenticated: (state: AppState) => state.isAuthenticated,
        isLoading: (state: AppState) => {
            return state.counterLoading > 0;
        },
    },
});

export default store as Store<AppState>;
