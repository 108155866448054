import { App } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faEnvelope,
    faLock,
    faCalendarCheck,
    faExchangeAlt,
    faQuestionCircle,
    faArchive,
    faHome,
    faClock,
    faBan,
} from '@fortawesome/free-solid-svg-icons';

import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';

import {
    faTimes,
    faBars,
    faCheckCircle,
    faCircle,
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-light-svg-icons';

library.add(
    faEnvelope,
    faLock,
    faCalendarCheck,
    faExchangeAlt,
    faArchive,
    faHome,
    faQuestionCircle,
    faBadgeCheck,
    faCheckCircle,
    faCircle,
    faClock,
    faTimes,
    faBars,
    faChevronRight,
    faChevronLeft,
    faBan
);

const configFA = (app: App<Element>) => {
    app.component('font-awesome-icon', FontAwesomeIcon);
};

export default configFA;
