import { MobileClient } from '@/../client-generator/generated-clients/client';
import axiosInstance from '../axios-instance';
import config from '../config/base';

const fruttaMeClient = new MobileClient(
    config.baseApiUrl,
    axiosInstance as any
);

export default fruttaMeClient;
